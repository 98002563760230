import React from "react";
import PropTypes from "prop-types";

const Button = React.memo(
  ({ className, label = "Button", onClick, type = "button", children, ...others }) => {
    return (
      <button
        type={type}
        className={`btn ${className}`}
        onClick={onClick}
        {...others}
      >
        {label}
          {children}
      </button>
    );
  }
);

Button.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
};

export default Button;
