import React, { startTransition } from 'react';
import { useNavigate } from 'react-router-dom';
import "../styles/notFound.css";
import Button from '../component/button/Button';

const NotFound = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        startTransition(() => {
            navigate("/")
        });
    };

    return (
        <section className="page_404">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 ">
                        <div className="col-sm-10 col-sm-offset-1  text-center">
                            <div className="four_zero_four_bg">
                                <h1 className="text-center ">404</h1>

                            </div>
                            <div className="contant_box_404">
                                <h3 className="h2">
                                    迷っているように見える
                                </h3>

                                <p>お探しのページが見つかりません！</p>

                                <Button className="link_404" onClick={handleClick}>Go to Home</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NotFound;