import { Suspense, lazy } from "react";
import LoadingSpinner from "../component/LoadingSpinner";
import MemberLogin from "../pages/member/login/MemberLogin";

const Terms = lazy(() => import("../pages/member/regist/Terms"));
const Registration = lazy(() => import("../pages/member/regist/Registration"));
const MemberRegistComp = lazy(() => import("../pages/member/regist/MemberRegistComp"));
const SettleEnd = lazy(() => import("../pages/SettleEnd"));
const MemberForgetPassword = lazy(() => import("../pages/member/passreset/MemberForgetPassword"));
const PasswordResetting = lazy(() => import("../pages/member/passreset/PasswordResetting"));
const PasswordResettingComp = lazy(() => import("../pages/member/passreset/PasswordResettingComp"));
const MemberSendUrl = lazy(() => import("../pages/member/passreset/MemberSendUrl"));
const AdminLogin = lazy(() => import("../pages/admin/login/AdminLogin"));

const publicRoutes = [
    { path: '/', element: <Suspense fallback={<LoadingSpinner />}> <MemberLogin /> </Suspense> },
    { path: '/admin', element: <Suspense fallback={<LoadingSpinner />}> <AdminLogin /> </Suspense> },
    { path: '/terms', element: <Suspense fallback={<LoadingSpinner />}> <Terms /> </Suspense> },
    { path: '/registration/input', element: <Suspense fallback={<LoadingSpinner />}> <Registration /> </Suspense> },
    { path: '/registration/comp', element: <Suspense fallback={<LoadingSpinner />}> <MemberRegistComp /> </Suspense> },
    { path: '/settle_end', element: <Suspense fallback={<LoadingSpinner />}> <SettleEnd /> </Suspense> },
    { path: '/forgetpassword', element: <Suspense fallback={<LoadingSpinner />}> <MemberForgetPassword /> </Suspense> },
    { path: '/reset_password', element: <Suspense fallback={<LoadingSpinner />}> <PasswordResetting /> </Suspense> },
    { path: '/reset_password_comp', element: <Suspense fallback={<LoadingSpinner />}> <PasswordResettingComp /> </Suspense> },
    { path: '/membersendcomp', element: <Suspense fallback={<LoadingSpinner />}> <MemberSendUrl /> </Suspense> },
]
export default publicRoutes;