import { Suspense, lazy } from "react";
import LoadingSpinner from "../../component/LoadingSpinner";

const MemberMypage = lazy(() => import("../../pages/member/main/MemberMypage"));
const MemberEventList = lazy(() => import("../../pages/member/event/MemberEventList"));
const MemberEventDetail = lazy(() => import("../../pages/member/event/MemberEventdetail"));
const MemberSendUrl = lazy(() => import("../../pages/member/passreset/MemberSendUrl"));
const MemberInfoChange = lazy(() => import("../../pages/member/info/MemberInfoChange"));
const Settle = lazy(() => import("../../pages/Settole"));


const privateMemberRoutes = [
        { path: '/mypage', element: <Suspense fallback={<LoadingSpinner />}> <MemberMypage /> </Suspense> },
        { path: '/event/list', element: <Suspense fallback={<LoadingSpinner />}> <MemberEventList /> </Suspense> },
        { path: '/event/detail', element: <Suspense fallback={<LoadingSpinner />}> <MemberEventDetail /> </Suspense> },
        { path: '/sendurl', element: <Suspense fallback={<LoadingSpinner />}>  <MemberSendUrl /> </Suspense> },
        { path: '/member', element: <Suspense fallback={<LoadingSpinner />}> <MemberInfoChange /> </Suspense> },
        { path: '/member/settle', element: <Suspense fallback={<LoadingSpinner />}> <Settle /> </Suspense> },
]

export default privateMemberRoutes;