import Header from "../../../component/header/Header";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import LoginInput from "../../../component/input/LoginInput";
import { AuthContext } from "../../../context/AuthContext";
const MemberLogin = () => {
  sessionStorage.setItem("user", "nomember");
  sessionStorage.setItem("Id", null);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const [Input, setInput] = useState({
    email: "",
    password: "",
  });
  const handleInput = (e) => {
    e.persist();
    setInput({ ...Input, [e.target.name]: e.target.value });
  };
  const loginSubmit = async (e) => {
    e.preventDefault();
    login(Input.email, Input.password)
      .then(() => {
        navigate("/mypage");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Header />
      <div className="login-clean">
        <form>
          <h4 className="text-center">
            会員専用ページ
            <br />
            ログイン
          </h4>
          <LoginInput
            type="email"
            name="email"
            onChange={handleInput}
            placeholder="メールアドレス"
          />
          <LoginInput
            type="password"
            name="password"
            onChange={handleInput}
            placeholder="パスワード"
          />
          <div className="mb-3">
            <button
              className="btn btn-primary d-block w-100"
              onClick={loginSubmit}
              type="submit"
            >
              ログイン
            </button>
          </div>
          <div className="mb-3">
            <p>
              <Link to="forgetpassword">パスワードをお忘れの方はこちら</Link>
            </p>
          </div>
        </form>

        <div className="d-flex justify-content-center mt-5">
          <Link className="btn btn-outline-dark" role="button" to="https://zenkeikyo.jp/contact/" target="_blank">お問い合わせはこちら</Link>
        </div>
      </div>
    </>
  );
};
export default MemberLogin;
