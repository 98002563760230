import axios from "axios";
const xAccessKeyMap = {
  localhost: "a8EVbR5t",
  "system.zenkeikyo.jp": "a8EVbR5t",
  "zenkeikyo.jp": "fG7sZLRq",
};
axios.defaults.headers.common["X-Access-Code"] =
  xAccessKeyMap[window.location.hostname];
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.withCredentials = true;
