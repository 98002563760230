const version = process.env.VERSION ?? "1.0.0";

const Footer =()=> {
    return(
        <footer>
            <p className="text-end px-3 py-1" style={{fontSize:"80%"}}>Copyright 一般社団法人 全国軽貨物協会 All Rights Reserved.</p>
            {version && <p className="text-end px-3" style={{fontSize:"70%", color:"#bbb"}}>ver. {version}</p>}
        </footer>
    );
};
export default Footer;