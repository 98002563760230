import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./component/footer/Footer";
import "./App.css"
import privateMemberRoutes from "./routes/member/privateMemberRoutes";
import privateAdminRoutes from "./routes/admin/privateAdminRoutes";
import publicRoutes from "./routes/publicRoutes";
import NotFound from "./pages/NotFound";

const GuestOnlyRoutes = lazy(() =>
  import("./routes/GuestOnlyRoutes")
);
const ProtectedMemberRoutes = lazy(() =>
  import("./routes/member/ProtectedMemberRoutes")
);
const ProtectedAdminRoutes = lazy(() =>
  import("./routes/admin/ProtectedAdminRoutes")
);

const App = () => {

  return (
    <>
      <Routes>
        {/* PUBLIC ROUTES */}
        <Route element={<GuestOnlyRoutes />}>
          {publicRoutes.map((i, index) => (
            <Route key={index} path={i.path} element={i.element} />
          ))}
        </Route>

        {/* MEMBER ROUTES */}
        <Route element={<ProtectedMemberRoutes />}>
          {privateMemberRoutes.map((i, index) => (
            <Route key={index} path={i.path} element={i.element} />
          ))}
        </Route>

        {/* ADMIN ROUTES */}
        <Route element={<ProtectedAdminRoutes />}>
          {privateAdminRoutes.map((i, index) => (
            <Route key={index} path={i.path} element={i.element} />
          ))}
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
