import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import BaseUrl from "../component/baseurl/BaseUrl";

export const AuthContext = createContext(null);

export const useAuth = () => {
  const { isAuthenticated, isAdmin } = useContext(AuthContext);
  return isAuthenticated && !isAdmin;
};

export const useAuthAdmin = () => {
  const { isAuthenticated, isAdmin } = useContext(AuthContext);
  return isAuthenticated && isAdmin;
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true); // 認証チェックが完了するまでの状態

  useEffect(() => {
    // サーバーに認証状態を確認するリクエストを送信
    const checkAuth = async () => {
      try {
        const response = await axios.post(BaseUrl + "auth_check", null);
        if (response.status === 200) {
          const id = response?.data?.id ?? null;
          const name = response?.data?.name ?? null;
          setIsAuthenticated(true);
          console.log("====================================");
          console.log("login check true", response.data);
          console.log("====================================");
          sessionStorage.setItem("Id", id);
          sessionStorage.setItem("name", name);
          if (response.data.role === "njmucha") {
            setIsAdmin(true);
            sessionStorage.setItem("user", "admin");
          } else {
            sessionStorage.setItem("user", "member");
          }
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
        console.log("====================================");
        console.log("login check false", error.message);
        console.log("====================================");
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const login = async (email, password) => {
    const data = {
      email,
      password,
    };
    try {
      const response = await axios.post(`${BaseUrl}member_login`, data);
      if (response.status === 200) {
        setIsAuthenticated(true);
        console.log("====================================");
        console.log("login", response.data);
        console.log("====================================");
        sessionStorage.setItem("Id", response.data.member_id);
        sessionStorage.setItem("user", "member");
        return response;
      } else if (response.data.result === "NG") {
        alert(response.data.err_info);
        throw new Error(response.data.err_info);
      }
    } catch (e) {
      if (e.response.data.err_info) {
        alert(e.response.data.err_info);
      } else {
        alert(e.message);
      }
      throw e;
    }
  };

  const loginAdmin = async (email, password) => {
    const data = {
      email,
      password,
    };
    try {
      const response = await axios.post(BaseUrl + `admin/login`, data);
      if (response.status === 200) {
        if (response.data.result === "OK") {
          setIsAuthenticated(true);
          setIsAdmin(true);
          sessionStorage.setItem("Id", response.data.admin_id);
          sessionStorage.setItem("name", response.data.admin_name);
          sessionStorage.setItem("user", "admin");
          return response;
        } else if (response.data.result === "NG") {
          alert(response.data.err_info);
          throw new Error(response.data.err_info);
        }
      }
    } catch (e) {
      if (e.response.data.err_info) {
        alert(e.response.data.err_info);
      } else {
        alert(e.message);
      }
      throw e;
    }
  };

  const logout = async () => {
    await axios.post(BaseUrl + `logout`, null);
    setIsAuthenticated(false);
    localStorage.clear();
    sessionStorage.clear();
  };

  const logoutAdmin = async () => {
    await axios.post(BaseUrl + `admin/logout`, null);
    setIsAuthenticated(false);
    localStorage.clear();
    sessionStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        loginAdmin,
        logout,
        logoutAdmin,
        loading,
        isAdmin,
      }}
    >
      {!loading ? children : <></>}
    </AuthContext.Provider>
  );
};
