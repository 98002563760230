import { Suspense, lazy } from "react";
import LoadingSpinner from "../../component/LoadingSpinner";

const AdminMypage = lazy(() => import("../../pages/admin/main/AdminMypage"));
const AdminMemberList = lazy(() => import("../../pages/admin/member/AdminMemberList"));
const AdminMemberDetail = lazy(() => import("../../pages/admin/member/AdminMemberDetail"));
const AdminMailList = lazy(() => import("../../pages/admin/mail/AdminMailList"));
const AdminMailRegist = lazy(() => import("../../pages/admin/mail/AdminMailRegist"));
const AdminMailDetail = lazy(() => import("../../pages/admin/mail/AdminMailDetail"));
const AdminMailAdd = lazy(() => import("../../pages/admin/mail/AdminMailAdd"));
const AdminEventList = lazy(() => import("../../pages/admin/event/AdminEventList"));
const AdminEventDetail = lazy(() => import("../../pages/admin/event/AdminEventDetail"));
const AdminEventAdd = lazy(() => import("../../pages/admin/event/AdminEventAdd"));
const AdminMailTemplateList = lazy(() => import("../../pages/admin/mail/AdminMailTemplateList"));
const AdminMailTemplateAdd = lazy(() => import("../../pages/admin/mail/AdminMailTemplateAdd"));
const ParticipationMail = lazy(() => import("../../pages/admin/mail/ParticipationMail"));
const AdminMembershipList = lazy(() => import("../../pages/admin/member/AdminMembershipList"));
const AdminList = lazy(() => import("../../pages/admin/admin/AdminList"));
const AdminDetail = lazy(() => import("../../pages/admin/admin/AdminDetail"));
const AdminAdd = lazy(() => import("../../pages/admin/admin/AdminAdd"));
const System = lazy(() => import("../../pages/admin/system/System"));


const privateAdminRoutes = [
    { path: '/admin_mypage', element: <Suspense fallback={<LoadingSpinner />}> <AdminMypage /> </Suspense> },
    { path: '/admin/member/list', element: <Suspense fallback={<LoadingSpinner />}> <AdminMemberList /> </Suspense> },
    { path: '/admin/member/detail', element: <Suspense fallback={<LoadingSpinner />}> <AdminMemberDetail /> </Suspense> },
    { path: '/admin/mail/list', element: <Suspense fallback={<LoadingSpinner />}> <AdminMailList /> </Suspense> },
    { path: '/admin/mail/regist', element: <Suspense fallback={<LoadingSpinner />}> <AdminMailRegist /> </Suspense> },
    { path: '/admin/mail/detail', element: <Suspense fallback={<LoadingSpinner />}> <AdminMailDetail /> </Suspense> },
    { path: '/admin/mail/Add', element: <Suspense fallback={<LoadingSpinner />}> <AdminMailAdd /> </Suspense> },
    { path: '/admin/event/list', element: <Suspense fallback={<LoadingSpinner />}> <AdminEventList /> </Suspense> },
    { path: '/admin/event/detail', element: <Suspense fallback={<LoadingSpinner />}> <AdminEventDetail /> </Suspense> },
    { path: '/admin/event/add', element: <Suspense fallback={<LoadingSpinner />}> <AdminEventAdd /> </Suspense> },
    { path: '/admin/template/list', element: <Suspense fallback={<LoadingSpinner />}> <AdminMailTemplateList /> </Suspense> },
    { path: '/admin/template/add', element: <Suspense fallback={<LoadingSpinner />}> <AdminMailTemplateAdd /> </Suspense> },
    { path: '/admin/template/participation', element: <Suspense fallback={<LoadingSpinner />}> <ParticipationMail /> </Suspense> },
    { path: '/admin/membership/list', element: <Suspense fallback={<LoadingSpinner />}> <AdminMembershipList /> </Suspense> },
    { path: '/admin/list', element: <Suspense fallback={<LoadingSpinner />}> <AdminList /> </Suspense> },
    { path: '/admin/detail', element: <Suspense fallback={<LoadingSpinner />}> <AdminDetail /> </Suspense> },
    { path: '/admin/add', element: <Suspense fallback={<LoadingSpinner />}> <AdminAdd /> </Suspense> },
    { path: '/admin/system', element: <Suspense fallback={<LoadingSpinner />}> <System /> </Suspense> },
]

export default privateAdminRoutes;